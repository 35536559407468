<template>
	<div class="footer">
		<div class="box padding-top-xl padding-bottom p-r">
			<el-divider>
				<a href="https://www.moj.gov.cn/" target="_blank">中华人民共和国司法部</a>
				<el-divider direction="vertical"></el-divider>

				<a href="https://sft.henan.gov.cn/" target="_blank">河南省司法厅</a>
				<el-divider direction="vertical"></el-divider>

				<a href="http://www.xinxiang.gov.cn/sitesources/xxsrmzf/page_pc/index.html" target="_blank">新乡市人民政府</a>
				<el-divider direction="vertical"></el-divider>

				<a href="https://sfj.xinxiang.gov.cn/" target="_blank">新乡市司法局</a>
			</el-divider>
			<div class="padding-top">
				<a :href="base + 'index/gywm'" target="_blank">关于我们</a>
				<el-divider direction="vertical"></el-divider>
				<a :href="base + 'index/lxwm'" target="_blank">联系我们</a>
				<el-divider direction="vertical"></el-divider>
				<a :href="base + 'index/wzdh'" target="_blank" class="hover-pointer">网站地图</a>
				<el-divider direction="vertical"></el-divider>
				<span class="">网站访问量：{{system_visits_number}}</span>
			</div>

			<!--   <div class="padding-tb-sm">
        <span>版权所有：新乡市司法局</span>
        <span class="margin-lr">技术支持：河南数慧信息技术有限公司</span>
        <span>电话：0371-61177851</span>
				<a href="https://beian.miit.gov.cn" target="_blank">备案编号：豫ICP备2020032394号-3</a>
				<span class="margin-lr">累计访问次数：59105</span>
      </div> -->
			<div class="padding-top-xs">
				<span>{{host_unit.uname}}：{{host_unit.valuess}}</span>
				<!-- <span class="margin-lr">{{technical.uname}}：{{technical.valuess}}</span> -->
				<span class="margin-lr">{{'地址'}}：{{'新乡市宏力大道东段433号'}}</span>
				<!-- <a href="https://beian.miit.gov.cn" target="_blank">{{bah.uname}}：{{bah.valuess}}</a> -->
				<span class="margin-lr">{{'电话'}}：{{'0373-2020512'}}</span>
			</div>

			<div class="padding-tb-xs">
				<span>{{'政府网站标识码'}}：{{'4107000032 '}}</span><a href="https://beian.miit.gov.cn/index.ftl#/Integrated/index" target="_blank" class="margin-left-xs">{{'豫ICP备2020032394号-1'}}</a>
				<span class="margin-lr"><img src="@/assets/img/footer/gabeian.png" alt=""><a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">{{' 豫公网安备 41071102000348号'}}</a></span>
				<!-- <a href="https://beian.miit.gov.cn" target="_blank">{{bah.uname}}：{{bah.valuess}}</a>
				<span class="margin-lr">网站访问量：{{system_visits_number}}</span> -->
			</div>
			<div>
				<span>技术支持：河南数慧信息技术有限公司</span>
				<!-- <a href="https://beian.miit.gov.cn" target="_blank">备案编号：豫ICP备2020032394号-3</a> -->
				<span class="margin-left-sm">为确保最佳浏览效果，建议您使用以下浏览器： Google Chrome浏览器、360浏览器极速模式。</span>
			</div>

			<div class="p-a">
				<!-- <span class="p-r">
					<img class="hover-pointer" src="@/assets/img/wx.png" alt="" style="width: 50px;">
					<span class="p-a padding-sm bg-white tc">
						<img class="margin-bottom-xs" src="@/assets/img/header/gzh.jpg" alt="" style="width: 130px; height: 130px;">
						<span>扫码关注</span><br />
						<span>法治新乡公众号</span>
					</span>
				</span>
				<span class="p-r">
					<img class="margin-lr-sm hover-pointer" src="@/assets/img/wb.png" alt="" style="width: 50px;">
					<span class="p-a padding-sm bg-white tc">
						<img class="margin-bottom-xs" src="@/assets/img/wbCode.png" alt="" style="width: 130px; height: 130px;">
						<span>扫码关注</span><br />
						<span>新乡司法行政在线</span>
					</span>
				</span>
				<span class="p-r">
					<img class="margin-right-sm hover-pointer" src="@/assets/img/sj.png" alt="" style="width: 50px;">
					<span class="p-a padding-sm bg-white tc">
						<img class="margin-bottom-xs" src="@/assets/img/m.png" alt="" style="width: 130px; height: 130px;">
						<span>扫码访问</span><br />
						<span>法治新乡移动版</span>
					</span>
				</span> -->
				<a target="_blank" href="https://bszs.conac.cn/sitename?method=show&id=09D70F144EDD512FE053012819AC3368"><img src="@/assets/img/footer/red.png" alt=""></a>
				<a target="_blank" href="https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=4107000032&url=https%3A%2F%2Fsfj.xinxiang.gov.cn%2F"><img src="@/assets/img/footer/jiucuo.png" alt="" style="width: 105px;"></a>
				<img src="@/assets/img/footer/sl.png" alt="" style="width: 105px;margin-left: 15px;">
			</div>
		</div>
	</div>
</template>
<script>
	import {
		get_sys_info,
		update_sys_visits
	} from "@/apis/index.js";
	export default {
		data() {
			return {
				base: process.env.BASE_URL,
				syslist: [],
				bah: {}, //备案号
				host_unit: {}, //版权所有
				technical: {}, //技术支持
				// system_visits: {}, //系统访问量,弃用
				system_visits_number: null, //系统访问量
			}
		},
		created() {
			this.selectsysinfo();
			this.add_system_visits();
		},
		methods: {
			selectsysinfo() {
				get_sys_info().then(res => {
					if (res.code == 200) {
						this.syslist = res.data;
						for(var i in res.data){
							if(res.data[i].key_id=='bah')this.bah = res.data[i];
							if(res.data[i].key_id=='host_unit')this.host_unit = res.data[i];
							if(res.data[i].key_id=='technical')this.technical = res.data[i];
							// if(res.data[i].key_id=='system_visits')this.system_visits = res.data[i];
						}
					}
				})
			}

			,add_system_visits() {
				update_sys_visits().then(res => {
					if (res.code == 200) {
						this.system_visits_number=res.data;
					}
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	$footer: #EDEDED;

	.footer {
		background-color: $footer;
		color: #7E7E7E;

		.box {
			background-color: $footer;
			@include main;

			.el-divider {
				background-color: #ADADAD;

				.el-divider__text {
					background-color: $footer;
					font-size: 18px;
					white-space: nowrap;
					padding: 0 15px;

					>a {
						color: #474747;
						font-size: 15px;
					}

					.el-divider {
						margin: 0 15px;
						background-color: #A1A1A1;
					}
				}
			}

			>div {
				span {
					font-size: 14px;
				}

				a {
					color: #7E7E7E;
					font-size: 14px;
				}

				.el-divider {
					margin: 0 15px;
					background-color: #D0D0D0;
				}
			}

			>.p-a {
				bottom: 40px;
				right: 0;

				.p-a {
					display: none;
					top: -260px;
					left: 50%;
					transform: translateX(-50%);
					line-height: 26px;
					z-index: 9;
					border-radius: 5px;
					box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
				}

				.p-r {
					&:hover {
						.p-a {
							display: block;
						}
					}
				}
			}
		}
	}

	// 适老
	.oldMode {
		.footer {
			.box {
				font-size: 26px;
				padding-bottom: 100px;

				.el-divider {
					.el-divider__text {
						font-size: 34px;

						>a {
							font-size: 24px;
						}
					}
				}

				>div {
					span {
						font-size: 22px;
					}

					a {
						font-size: 22px;
					}
				}

				>.p-a {
					left: 50%;
					transform: translateX(-50%);
					bottom: 20px;
					text-align: center;

					.p-a {
						top: -280px;
					}
				}
			}
		}
	}
</style>
