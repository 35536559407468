// 截取时间
function getTimeForm(fullTime) {
	if (fullTime != null || fullTime != "" || fullTime != undefined) {
		var timeArr = fullTime.replace(" ", ":").replace(/\:/g, "-").split("-");
		var nian = timeArr[0];
		var yue = timeArr[1];
		var ri = timeArr[2];
		var str = nian + "-" + yue + "-" + ri;
		return str;
	} else {
		return "";
	}
}

// 富文本编辑器样式去除    
function removeHtmlStyle(html){
	// this.content = content.replace(/&nbsp;/gi, "").replace(/<br\/?>/gi, '\n').replace(/(　*)|(　*$)/g,"");
	
	let relStyle = /style\s*?=\s*?([‘"])[\s\S]*?\1/g; //去除样式
	//let relTag = /<.+?>/g; //去除标签
	let relTag = /&nbsp;/gi;
	//let relTag2 = /(<\w+>[ \t\r\n]*)/g;//去除
	let relTag2 = /(　*)|(　*$)/g;//
	//let rel3 = /<img[^>]*\sheight=['"][^'"]*['"][^>]*>/g;//img 标签中的 height 属性
	let rel3 = /height="(.*?)"/ig;//img 标签中的 height 属性
	let newHtml = "";
	if (html) {
		newHtml = html.replace(relStyle, "");
		newHtml = newHtml.replace(relTag, '');
		newHtml = newHtml.replace(relTag2, '');
		newHtml = newHtml.replace(rel3, '');
	}
	return newHtml;
}

export {
	getTimeForm,
	removeHtmlStyle
};