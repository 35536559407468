import Vue from "vue";
import Vuex from "vuex";
import VueCookies from "vue-cookies";
import {getUserinfo} from "@/apis/login.js";
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		userinfo: null || JSON.parse(localStorage.getItem('userinfo')),
		oldMode: localStorage.getItem('oldMode') || false,
	},
	getters: {
		isLogin: (state) => {
			return !state.userinfo ? false : true;
		}
	},
	mutations: {
		loginOut(state, data) {//退出登录删除用户信息
			VueCookies.remove("usertoken");
			localStorage.removeItem('userinfo');
			state.userinfo = null;
		},
		updateUserinfo(state, data) {
			state.userinfo = data;
		}
	},
	actions: {
		loadUserinfo({commit, state}, data) {
			// console.log(userLogin)
			getUserinfo().then(res => {
				console.log(res)
				if(res.code == 200) {
					localStorage.setItem('userinfo', JSON.stringify(res.data));
					commit('updateUserinfo', res.data);
				}
			})
		}
	},
	modules: {}
})
