<template>
	<div class="home" id="home">
		<Header />
		<router-view />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue'
	import Footer from '@/components/footer/footer.vue'

	export default {
		name: 'HomeView',
		components: {
			Header,
			Footer
		},
		created() {
			
		},
		mounted() {
			
		},
		methods: {
		
		}
	}
</script>
<style lang="scss">
	#home {
		min-width: $minWidth;
	}
	@media (max-width: 768px) {
	  .home {
	    /* 解决手机端高度不够个别页面内容错乱问题 */
			min-height: 2600px;
		}
	}
</style>

