<template>
  <div id="app" :class="{ oldMode: $store.state.oldMode }">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
		window._publicPath = process.env.BASE_URL;
	},
  methods: {
   
  },
};
</script>

<style lang="scss">
	@import url("./assets/iconfont/iconfont.css");
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
	font-family: Arial, "PingFang SC", "Helvetica Neue", Helvetica, sans-serif, system-ui, -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
	font-size: 14px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@media (max-width: 768px) {
	html {
		font-size: 16px;
	}
}
</style>
<style>
	@media (max-width: 768px) {
		.el-image-viewer__wrapper .el-image-viewer__canvas img {zoom: 2!important;}
		.el-image-viewer__wrapper .el-image-viewer__btn {display: none;}
	}
</style>
