import request from "./request";

//字典
export function getCode(data) {
  return request({
    url: "/pub/select_sys_dict_type",
    method: "post",
    data: data
  });
}
// 首页
// 法治动态 / 法治要闻 / 法治公告/法治政府/法治社会/法治调研/法治督察
export function getSameIndex(data) {
  return request({
    url: "/userapi/index/select_data_list",
    method: "post",
    data: data
  });
}
// 首页--轮播图
export function getCoucel(data) {
  return request({
    url: "/userapi/index/select_index_lbtp",
    method: "post",
    data: data
  });
}

//法治要闻/法治公告
export function fzyw_fzgg(data) {
  return request({
    url: "/userapi/index/select_dtyw_sysh",
    method: "post",
    data: data
  });
}


// 首页推荐（单条）
export function recommendOne(data) {
  return request({
    url: "/userapi/index/select_index_zhiding_data",
    method: "post",
    data: data
  });
}

// 首页推荐文章(4条)
export function recommendData(data) {
  return request({
    url: "/userapi/index/select_index_tuijian_list",
    method: "post",
    data: data
  });
}

//专题专栏
export function ztzlData(data) {
  return request({
		url: "/userapi/index/select_index_ztzl_list",
    method: "post",
    data: data
  });
}

// 专题专栏列表页
export function ztzlListData(data) {
  return request({
    url: "/userapi/index/select_ztzl_list",
    method: "post",
    data: data
  });
}

//专题专栏-详情
export function ztzlDetails(data) {
  return request({
    url: "/userapi/ztzl/select_ztzl_info",
    method: "post",
    data: data
  });
}

// 通用详情
export function getSameDetail(data) {
  return request({
    url: "/userapi/index/select_data_one",
    method: "post",
    data: data
  });
}
// 组织领导--上半部分机构简介
export function getOrganLeader(data) {
  return request({
    url: "/userapi/zzld/select_zzld_intro?yulan=" + data.yulan,
    method: "post",
    data: data
  });
}
// 组织领导--中间的机构设置
export function getOrganSet(data) {
  return request({
    url: "/userapi/zzld/select_zzld_org",
    method: "post",
    data: data
  });
}
// 组织领导--下半部分的成员单位
export function getPersonLeader(data) {
  return request({
    url: "/userapi/zzld/select_zzld_cydw",
    method: "post",
    data: data
  });
}

// 组织领导 机构设置  日期及浏览量
export function getTimeNum(data) {
  return request({
    url: `/userapi/zzld/update_seenum/${data.id}`,
    method: "post",
    data: data
  });
}

// 动态要闻
// 动态要闻--法制视频
export function getLawVideo(data) {
  return request({
    url: "/userapi/dtyw/select_dtyw_fzsp_list",
    method: "post",
    data: data
  });
}

// 动态要闻-图片新闻
export function getTpxwlist(data) {
  return request({
    url: "/userapi/dtyw/select_tpxw_list",
    method: "post",
    data: data
  });
}

// 动态要闻-图片新闻详情
export function getTpxwDetails(data) {
  return request({
    url: "/userapi/dtyw/select_tpxw_one",
    method: "post",
    data: data
  });
}


//法制视频详情
export function getVideoDetails(data) {
  return request({
    url: "/userapi/dtyw/select_dtyw_fzsp_one",
    method: "post",
    data: data
  });
}
//咨询专家
export function getExpertList(data) {
  return request({
    url: "/userapi/kxlf/select_lfzxzj_list",
    method: "post",
    data: data
  });
}
//立法咨询专家详情
export function zxzjDetails(data) {
  return request({
    url: "/userapi/kxlf/select_lfzxzj_list_one",
    method: "post",
    data: data
  });
}

// 搜索
export function SearchData(data) {
	return request({
	  url: "/userapi/search/multi_type",
	  method: "post",
	  data: data
	});
}

// 搜索右侧下拉框
export function SearchRight(data) {
	return request({
	  url: "/userapi/search/multi_type_select",
	  method: "post",
	  data: data
	});
}

//搜索点击查看更多
export function SearchMore(data) {
	return request({
	  url: "/userapi/search/multi_type_list",
	  method: "post",
	  data: data
	});
}

// 基层立法联系点
export function jclflxdData(data) {
	return request({
	  url: "/userapi/kxlf/select_jclflxd_list",
	  method: "post",
	  data: data
	});
}

export function jclflxdDetails(data) {
	return request({
	  url: "/userapi/kxlf/select_jclflxd_one",
	  method: "post",
	  data: data
	});
}


// 深入贯彻习近平法治思想
export function xxgcFzsx(data) {
	return request({
	  url: "/userapi/index/select_xxgc_info",
	  method: "post",
	  data: data
	});
}


// 深入贯彻习近平法治思想-理论前沿详情
export function llqyDetails(data) {
	return request({
	  url: "/userapi/index/select_xxgc_info_one",
	  method: "post",
	  data: data
	});
}

// 书架
export function bookData(data) {
	return request({
	  url: "/userapi/index/select_bookshelf",
	  method: "post",
	  data: data
	});
}

// 左右两侧广告
export function advertising(data) {
	return request({
	  url: "/userapi/index/select_adverts",
	  method: "post",
	  data: data
	});
}


// 下边的 foot
export function get_sys_info(data) {
  return request({
    url: "/pub/get_sys_info",
    method: "post",
    data: data
  });
}

// 增加系统访问量
export function update_sys_visits(data) {
  return request({
    url: "/pub/update_sys_visits",
    method: "post",
    data: data
  });
}


// 获取微信签名
export function get_wechat_signature(data) {
  return request({
    url: "/pub/get_wechat_signature",
    method: "post",
    data: data
  });
}

// 行政执法监督   群众建议
export function select_mh_hint(data) {
  return request({
    url: "/userapi/fzwm/select_mh_hint",
    method: "post",
    data: data
  });
}

// 关于我们/联系我们
export function getGywmLxwm(data) {
  return request({
    url: '/userapi/index/about_us',
    method: "post",
    data: data
  });
}
