import request from "./request";

// 发送短信验证码
export function sendMessage(data) {
	return request({
	  url: "/userapi/login/send_sms",
	  method: "post",
	  data: data
	});
}

// 发送邮箱验证码
export function sendEmail(data) {
	return request({
	  url: "/userapi/login/send_email_code",
	  method: "post",
	  data: data
	});
}

// 注册接口
export function userRegister(data) {
	return request({
	  url: "/userapi/login/register",
	  method: "post",
	  data: data
	});
}

//登录
export function userLogin(data) {
	return request({
	  url: "/userapi/login/login_phone_pwd",
	  method: "post",
	  data: data
	});
}

// 忘记密码
export function forgetPwd(data) {
	return request({
	  url: "/userapi/login/update_pwd",
	  method: "post",
	  data: data
	});
}

// 用户信息
export function getUserinfo(data) {
	return request({
	  url: "/userapi/user/get_user_info",
	  method: "post",
	  data: data
	});
}

// 修改用户名
export function editNickname(data) {
	return request({
	  url: "/userapi/user/update_user_nick_name",
	  method: "post",
	  data: data
	});
}

// 公共上传
export function uploadAvatar(data) {
	return request({
	  url: "/pub/public_file_upload",
	  method: "post",
	  data: data
	});
}

// 修改头像
export function editAvatar(data) {
	return request({
	  url: "/userapi/user/update_user_img_filepath",
	  method: "post",
	  data: data
	});
}


// 修改密码
export function changePwd(data) {
	return request({
	  url: "/userapi/user/update_pwd_by_ypwd",
	  method: "post",
	  data: data
	});
}

//修改手机号
export function changePhone(data) {
	return request({
	  url: "/userapi/user/update_phone",
	  method: "post",
	  data: data
	});
}

//修改邮箱号
export function changeEmail(data) {
	return request({
	  url: "/userapi/user/update_email",
	  method: "post",
	  data: data
	});
}

//绑定邮箱号/手机号
export function bindAccount(data) {
	return request({
	  url: "/userapi/user/bind_phone_email",
	  method: "post",
	  data: data
	});
}

// 我的留言
export function getWdly(data) {
	return request({
	  url: "/userapi/user/select_my_opinion",
	  method: "post",
	  data: data
	});
}

export function wdlyNumber(data) {
	return request({
	  url: "/userapi/user/select_opinion_reply",
	  method: "post",
	  data: data
	});
}

// 我的留言详情
export function wdlyDetails(data) {
	return request({
	  url: "/userapi/user/select_my_opinion_one",
	  method: "post",
	  data: data
	});
}

// 我的意见
export function getWdyj(data) {
	return request({
	  url: "/userapi/user/select_my_lfyjzjca_zjfk",
	  method: "post",
	  data: data
	});
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get',
    timeout: 20000
  })
}