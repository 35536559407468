import axios from "axios";
import { Message } from "element-ui";
import store from "@/store/index.js";
// axios实例
const instance = axios.create({
  // baseURL: '',
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  //  "Access-Control-Allow-Origin":"*",//允许所有来源访问
  //   "Access-Control-Allow-Method":"POST,GET"//允许访问的方式 　
  },
  changeOrigin: true,
  withCredentials: true,
});
// 请求拦截器
instance.interceptors.request.use(
  config => {
    // const token = localStorage.getItem('token');
    // token && (config.headers["Authorization"] = token);
    return config;
  },
  error => {
    return Promise.error(error);
  }
);
// 响应拦截器
instance.interceptors.response.use(
  response => {
    if (response.status === 200) {
      let result = response.data;
      if (result.code == 95 || result.code == 96) {//未登录的95登录失效
				Message.error('请登录！')
        store.commit('loginOut');
        return Promise.reject(result);
      } else if(result.code == 200){
        return Promise.resolve(result);
      }else {//catch
				Message.error(result.msg);
				return Promise.reject(response);
			}
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    console.log("拦截器", error);
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        // 未登录
        case 401:
          Message.error("请登录");
          store.commit("loginOut");
          break;
        // 403 token 过期
        case 403:
          Message.error("登录已过期");
          store.commit("loginOut");
          break;
        // 请求不存在
        case 404:
          Message.error("请求的资源不存在");
          break;
        default:
          Message.error(error.response.data.message);
          break;
      }
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error.response);
    }
  }
);

export default instance;
