<template>
  <div class="header">
		<div class="transformBox">
			<img class="img1" src="@/assets/img/header/fog-2.png" alt="">
			<img class="img2" src="@/assets/img/header/fog-2.png" alt="">
		</div>
    <div class="header_top p-r flex justify-between">
      <div class="left">
        <span class="hover-pointer p-r">
          <img src="@/assets/img/header/wx.png" alt="">
          <span class="text">微信</span>
          <span class="p-a padding-sm bg-white tc">
            <img class="margin-bottom-xs" src="@/assets/img/header/gzh.jpg" alt="" style="width: 130px; height: 130px;">
            <span>扫码关注</span><br/>
            <span>法治新乡公众号</span>
          </span>
        </span>
        <el-divider direction="vertical"></el-divider>

        <span class="hover-pointer">
					<a href="https://weibo.com/u/5190281745" target="_blank">
						<img src="@/assets/img/header/wb.png" alt="">
						<span class="text">微博</span>
					</a>
        </span> 
        <el-divider direction="vertical"></el-divider>

        <span class="hover-pointer p-r">
          <img src="@/assets/img/header/ydd.png" alt="">
          <span class="text">移动版</span>
          <span class="p-a padding-sm bg-white tc">
            <img class="margin-bottom-xs" src="@/assets/img/m.png" alt="" style="width: 130px; height: 130px;">
            <span>扫码访问</span><br/>
            <span>法治新乡移动版</span>
          </span>
        </span>
        <el-divider direction="vertical"></el-divider>

        <!-- <a :href="base + 'questionAnswer'" target="_blank">
          <img src="@/assets/img/header/ai.png" alt="">
          <span class="text">法小新</span>
        </a> -->
         <a   @click="goPage()" style="cursor:pointer;">
          <img src="@/assets/img/header/ai.png" alt="">
          <span class="text">法小新</span>
        </a>
      </div>

      <div class="right">
        <a class="text yellow wzamo" title="无障碍模式" href="javascript:;" @click="ShowDetail" accesskey="g" target="_self">无障碍模式</a>
        <el-divider direction="vertical"></el-divider>

        <span class="text yellow" @click="handleOldMode">{{ oldMode ? '关闭适老模式' : '适老模式' }}</span>
        <el-divider direction="vertical"></el-divider>

        <span class="text black" @click="$message.info('请使用Ctrl+D进行收藏本站')">收藏本站</span>
        <el-divider direction="vertical"></el-divider>

        <!-- <span class="text black">个人中心</span>
				
				<el-divider direction="vertical"></el-divider> -->
				<a :href="isLogin ? base + 'center' : base + 'login'" class="text black" target="_blank">{{isLogin ? '个人中心' : '登录'}}</a>
				
				<!-- <el-divider direction="vertical"></el-divider>
				<span class="text black">注册</span> -->
				
				
      </div>
    </div>
    <!-- logo/搜索 -->
    <div class="logo_search p-r flex align-end justify-between">
      <a href="/"><img class="hover-pointer" src="@/assets/img/header/logo.png" alt=""></a>
      <div class="searchBox flex">
        <el-input placeholder="请输入搜索关键词" v-model="searchVal" class="input-with-select">
          <!-- <el-button slot="append" icon="el-icon-search" type="primary" @click="handleSearch">搜索</el-button> -->
        </el-input>
				<el-button icon="el-icon-search" type="primary" @click="handleSearch">搜索</el-button>
      </div>
    </div>
    <!-- 导航 -->
    <div class="nav">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" background-color="#004881"
        text-color="#fff" active-text-color="#fff" :router="true">
        <el-menu-item :index="item.path" v-for="(item, index) in nav" :key="index"><a>{{item.title}}</a></el-menu-item>
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
			base: process.env.BASE_URL,
      oldMode: localStorage.getItem('oldMode') || false,
      searchVal: '',
      // activeIndex: 'index',
      nav: [
        {title: '首页', path: '/'},
        {title: '组织领导', path: '/zzld'},
        {title: '动态要闻', path: '/dtyw'},
				{title: '法治为民', path: '/fzwm'},
        {title: '科学立法', path: '/kxlf'},
        {title: '严格执法', path: '/ygzf'},
        {title: '公正司法', path: '/gzsf'},
        {title: '全民守法', path: '/qmsf'},
        {title: '法律法规', path: '/flfg'}
      ]
    }
  },
  computed: {
    activeIndex() {
      let name = "/" + this.$route.name,
        path = this.$route.path;
      if (name == path) {
        return path;
      } else {
        return path.replace(name, "");
      }
    },
		isLogin() {
			return this.$store.getters.isLogin;
		}
  },
  created() {
  },
  methods: {
     isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      );
      return flag;
    },
    goPage() {
      if (this.isMobile()) {
        window.open("./questionAnswerPhone");
      } else {
        window.open("./questionAnswer");
      }
    },
		ShowDetail() {
			free_Web.Function.show.Start();
			// if (window.top.document.getElementById("rrbay_wzatool")) {
			// 	return false;
			// }
			// var url = "?url=" + document.location.href;
			// window.scrollTo(0, 0);
			// this.location_href(process.env.BASE_URL + 'testwuzhangai/help.html'  + url);
		},
		location_href(url) { location.href = url },
    handleOldMode() {
      this.oldMode = !this.oldMode;
      this.oldMode ? localStorage.setItem('oldMode', true) : localStorage.removeItem('oldMode');
      this.$router.go();
    },
		handleSearch() {
			window.open(`${this.base}search?val=${this.searchVal}`);
		}
  }
}
</script>
<style lang="scss" scoped>
.header {
  position: relative;
  height: 333px;
  background: url('@/assets/img/header/top.png')no-repeat center;
  background-size: cover;
	
	
	// @keyframes yd{
	// 	to{
	// 		transform: translate(-1333px);
	// 	}
	// }
	
	@keyframes loop {
			0% {
					left: 0%;
			}
			
			100% {
					left: -100%;
			}
	}
	@keyframes loop2 {
			0% {
					left: 100%;
			}
			
			100% {
					left: 0%;
			}
	}
	.transformBox {
		position: absolute;
		width: 100%;
		overflow: hidden;
		height: calc(100% - 50px);
		img {
			width: 100%;
			height: 100%;
			position: absolute;
		}
		.img1 {
			animation: loop 18s linear infinite;
		}
		.img2 {
			left: 100%;	
			animation: loop2 18s linear infinite;
		}
	}
	
  .header_top {
    @include main;
    font-size: $mainSize;
    padding-top: 20px;

    .el-divider {
      height: 16px;
      background: #A1A1A1;
    }

    .left {
      color: #7E7E7E;
      .p-r {
        &:hover {
          .p-a {
            display: inline-block;
          }
        }
      }
      .p-a {
        display: none;
        top: 40px;
        line-height: 26px;
        z-index: 9;
        transform: translateX(-75%);
        border-radius: 5px;
				z-index: 99;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
      }
      .text {
        margin: 0 20px 0 6px;
      }
			a {
				color: #7E7E7E;
			}
      .el-divider {
        margin-right: 20px;
      }
    }

    img {
      width: 20px;
      height: 20px;
    }

    .right {
      .yellow {
        color: #F79600;
      }

      .black {
        color: #353535;
      }

      .text {
        margin: 0 13px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .logo_search {
    @include main;
    padding-top: 66px;

    img {
      width: 330px;
    }

    .searchBox {
			::v-deep .el-input__inner {
				width: 390px;
			  height: 40px;
			  font-size: 18px;
			  border: none;
			  color: $blue;
				border-radius: 6px 0 0 6px;
				border: 1px solid #ddd;
				border-right: none;
			  &::placeholder {
			    font-size: 16px;
			    color: #96B3D2;
			  }
			}
			.el-button {
				height: 40px;
				border-radius: 0px 6px 6px 0;
				background-color: $blue;
				border-color: $blue;
				font-size: 16px;
				.el-icon-search {
					font-size: 20px;
					vertical-align: middle;
					color: #fff;
				 }
			}
    }
  }

  $h: 50px;

  .nav {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $h;
    background-color: #004881;

    .el-menu {
      // width: calc(#{$width} + 60px);
      width: $width;
      margin: 0 auto;
      border: none;

      .el-menu-item {
        border: none;
        height: $h;
        line-height: $h;
        font-size: 20px;
        width: 11.11%;
        text-align: center;
        font-weight: bold;
        // font-family: '黑体';
				font-family: Arial, "PingFang SC", "Helvetica Neue", Helvetica, sans-serif, system-ui, -apple-system, BlinkMacSystemFont;
				a {
					display: block;
					width: 100%;
					height: 100%;
				}
        &:hover,
        &.is-active {
					a {
						position: relative;
						z-index: 9;
						height: calc(#{$h} + 4px);
						background-color: $red;
						box-shadow: 0px 1px 3px 1px #740000;
					}
        }
      }
    }

  }
}

// 适老模式
.oldMode {
  .header {
    .nav {
      .el-menu {
        .el-menu-item {
          font-size: 30px;
        }
      }
    }
  }
}
</style>