import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: HomeView,
    redirect: '',
    children: [
      {
        path: '',
				redirect: '/',
        component: () => import('@/views/index/index.vue'),
				meta: {
					title: '首页'
				},
				children: [
					{//首页
						path: '/',
						name: 'index',
						component: () => import('@/views/index/indexCon.vue'),
					},
					// {//二级列表页
					// 	path: '/index/indexList',
					// 	name: 'indexList',
					// 	component: () => import('@/views/list-details/lists.vue'),
					// },
					{//文章详情
						path: '/index/indexDetails',
						name: 'indexDetails',
						component: () => import('@/views/list-details/detail.vue'),
					},
					{//网站导航
						path: '/index/wzdh',
						name: 'wzdh',
						component: () => import('@/views/index/wzdh.vue'),
					},
					{
						path: '/index/fzzf',
						name: 'fzzf',
						component: () => import('@/views/index/fzzf.vue'),
					},
					{
						path: '/index/fzsh',
						name: 'fzsh',
						component: () => import('@/views/index/fzsh.vue'),
					},
					{//关于我们
						path: '/index/gywm',
						name: 'gywm',
						component: () => import('@/views/index/gywm.vue'),
					},
					{//关于我们
						path: '/index/lxwm',
						name: 'lxwm',
						component: () => import('@/views/index/lxwm.vue'),
					},
					{//搜索资讯详情
						path: '/searchDetails',
						name: '/searchDetails',
						component: () => import('@/views/list-details/detail.vue')
					},
					{//理论前沿详情
						path: '/index/llqyDetails',
						name: '/llqyDetails',
						component: () => import('@/views/index/llqyDetails.vue')
					},
						{//首页理论前沿--列表页
							path: '/index/moreList',
							name: '/moreList',
							component: () => import('@/views/index/more.vue')
						},
				]
      },
      {//组织领导
        path: '',
				redirect: 'zzld',
        component: () => import('@/views/zzld/zzld.vue'),
				children: [
					{
						path: 'zzld',
						name: 'zzld',
						component: () => import('@/views/zzld/zzldCon.vue')
					},
					{//成员单位 查看更多
						path: '/zzld/cydw',
						name: 'cydw',
						component: () => import('@/views/zzld/cydw.vue')
					},
					{//机构设置
						path: '/zzld/jgsz',
						name: 'jgsz',
						component: () => import('@/views/zzld/jgsz.vue')
					},
				]
      },
      {//动态要闻
        path: '',
				redirect: 'dtyw',
        component: () => import('@/views/dtyw/dtyw.vue'),
				meta: {
					title: '动态要闻'
				},
				children: [
					{
						path: 'dtyw',
						name: 'dtyw',
						component: () => import('@/views/dtyw/dtywCon.vue'),
					},
					{//动态要闻-二级列表页
						path: '/dtyw/dtywList',
						name: 'dtywList',
						component: () => import('@/views/list-details/lists.vue')
					},
					
					{//文章详情
						path: '/dtyw/dtywDetails',
						name: 'dtywDetails',
						component: () => import('@/views/list-details/detail.vue'),
					},
					{//专题专栏
						path: '/dtyw/ztzl',
						name: 'ztzl',
						component: () => import('@/views/dtyw/ztzl.vue')
					},
					{//法治视频
						path: '/dtyw/fzsp',
						name: 'fzsp',
						component: () => import('@/views/dtyw/fzsp.vue')
					},
					{//法治视频详情
						path: '/dtyw/fzspDetails',
						name: 'fzspDetails',
						component: () => import('@/views/dtyw/fzspDetails.vue')
					},
					{//图片新闻
						path: '/dtyw/tpxw',
						name: 'tpxw',
						component: () => import('@/views/dtyw/tpxw.vue')
					},
					{//图片新闻详情
						path: '/dtyw/tpxwDetails',
						name: 'tpxwDetails',
						component: () => import('@/views/dtyw/tpxwDetails.vue')
					},
				]
      },
			{//法治为民
				path: '',
				redirect: 'fzwm',
				component: () => import('@/views/fzwm/fzwm.vue'),
				meta: {
					title: '法治为民'
				},
				children: [
					{
						path: 'fzwm',
						name: 'fzwm',
						component: () => import('@/views/fzwm/fzwmCon.vue')
					},
					{//法治为民-二级列表页
						path: '/fzwm/fzwmList',
						name: 'fzwmList',
						component: () => import('@/views/fzwm/allList.vue')
					},
					{//文章详情
						path: '/fzwm/fzwmDetails',
						name: 'fzwmDetails',
						// component: () => import('@/views/list-details/detail.vue'),
						component: () => import('@/views/fzwm/detail.vue'),
					},
					{//咨询留言
						path: '/fzwm/zxly',
						name: 'zxly',
						component: () => import('@/views/fzwm/zxly.vue'),
					},
					// 阅读全文
						{
							path: '/fzwm/readMore',
							name: 'readMore',
							component: () => import('@/views/fzwm/yjzjxt/readMore.vue'),
					},
							// 反馈公开详情
							{
								path: '/fzwm/feedDetail',
								name: 'feedDetail',
								component: () => import('@/views/fzwm/feedDetail.vue'),
							},
				]
			},
			{//科学立法
				path: '',
				redirect: 'kxlf',
				component: () => import('@/views/kxlf/kxlf.vue'),
				meta: {
					title: '科学立法'
				},
				children: [
					{
						path: 'kxlf',
						name: 'kxlf',
						component: () => import('@/views/kxlf/kxlfCon.vue')
					},
					{//科学立法-二级列表页
						path: '/kxlf/kxlfList',
						name: 'kxlfList',
						component: () => import('@/views/list-details/lists.vue')
					},
					{//文章详情
						path: '/kxlf/kxlfDetails',
						name: 'kxlfDetails',
						component: () => import('@/views/list-details/detail.vue'),
					},
					{//立法咨询专家
						path: '/kxlf/lfzxzj',
						name: 'lfzxzj',
						component: () => import('@/views/kxlf/lfzxzj.vue'),
					},
					{//立法咨询专家
						path: '/kxlf/lfzxzjDetails',
						name: 'lfzxzjDetails',
						component: () => import('@/views/kxlf/lfzxzjDetails.vue'),
					},
					{//基层立法联系点
						path: '/kxlf/jclflxd',
						name: 'jclflxd',
						component: () => import('@/views/kxlf/jclflxd.vue'),
					},
					{//基层立法联系点
						path: '/kxlf/jclflxdDetails',
						name: 'jclflxdDetails',
						component: () => import('@/views/kxlf/jclflxdDetails.vue'),
					}
				]
			},
			{//严格执法
				path: '',
				redirect: 'ygzf',
				component: () => import('@/views/ygzf/ygzf.vue'),
				meta: {
					title: '严格执法'
				},
				children: [
					{
						path: 'ygzf',
						name: 'ygzf',
						component: () => import('@/views/ygzf/ygzfCon.vue')
					},
					{//严格执法-二级列表页
						path: '/ygzf/ygzfList',
						name: 'ygzfList',
						component: () => import('@/views/list-details/lists.vue')
					},
					{//文章详情
						path: '/ygzf/ygzfDetails',
						name: 'ygzfDetails',
						component: () => import('@/views/list-details/detail.vue'),
					},
					{//专项治理
						path: '/ygzf/zxzl',
						name: 'zxzl',
						component: () => import('@/views/ygzf/zxzl.vue'),
					}
				]
			},
			{//公正司法
				path: '',
				redirect: 'gzsf',
				component: () => import('@/views/gzsf/gzsf.vue'),
				meta: {
					title: '公正司法'
				},
				children: [
					{
						path: 'gzsf',
						name: 'gzsf',
						component: () => import('@/views/gzsf/gzsfCon.vue')
					},
					{//公正司法-二级列表页
						path: '/gzsf/gzsfList',
						name: 'gzsfList',
						component: () => import('@/views/list-details/lists.vue')
					},
					{//文章详情
						path: '/gzsf/gzsfDetails',
						name: 'gzsfDetails',
						component: () => import('@/views/list-details/detail.vue'),
					},
					{//典型司法案例
						path: '/gzsf/dxsfal',
						name: 'dxsfal',
						component: () => import('@/views/gzsf/dxsfal.vue'),
					}
				]
			},
			{//全民守法
				path: '',
				redirect: 'qmsf',
				component: () => import('@/views/qmsf/qmsf.vue'),
				meta: {
					title: '全民守法'
				},
				children: [
					{
						path: 'qmsf',
						name: 'qmsf',
						component: () => import('@/views/qmsf/qmsfCon.vue')
					},
					{//全民守法-二级列表页
						path: '/qmsf/qmsfList',
						name: 'qmsfList',
						component: () => import('@/views/list-details/lists.vue')
					},
					{//文章详情
						path: '/qmsf/qmsfDetails',
						name: 'qmsfDetails',
						component: () => import('@/views/list-details/detail.vue'),
					}
				]
			},
			{//法律法规
				path: '',
				redirect: 'flfg',
				component: () => import('@/views/flfg/flfg.vue'),
				meta: {
					title: '法律法规'
				},
				children: [
					{
						path: 'flfg',
						name: 'flfg',
						component: () => import('@/views/flfg/flfgCon.vue')
					}
				]
			}
    ]
  },
	{//深入学习贯彻
		path: '/xxgc',
		name: 'xxgc',
		component: () => import('@/views/index/xxgc.vue')
	},
	{//专题专栏  乡村振兴
		path: '/xczx',
		name: 'xczx',
		component: () => import('@/views/index/ztzl/xczx.vue')
	},
	{//专题专栏  优化法治化营商运营环境
		path: '/yhfzhyshj',
		name: 'yhfzhyshj',
		component: () => import('@/views/index/ztzl/yhfzhyshj.vue')
	},
	{//专题专栏  交通领域专项整治专题
		path: '/jtlyzxzzzt',
		name: 'jtlyzxzzzt',
		component: () => import('@/views/index/ztzl/jtlyzxzzzt.vue')
	},
	{//专题专栏-详情
		path: '/ztzlDetails',
		name: 'ztzlDetails',
		component: () => import('@/views/index/ztzlDetails.vue'),
	},
	{//法治地图
		path: '/fzdt',
		name: 'fzdt',
		component: () => import('@/views/index/fzdt/fzdt.vue')
	},
	{//行政执法监督平台
	  path: '/xzzfjdpt',
	  name: 'xzzfjdpt',
	  component: () => import('@/views/fzwm/xzzfjdpt/xzzfjdpt.vue'),
		beforeEnter(to, from, next) {
			const meta = document.createElement('meta');
			meta.name = 'viewport';
			meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
			document.getElementsByTagName('head')[0].appendChild(meta);
			next();
		}
	},
	{//意见征集系统
		path: '',
		redirect: 'yjzjxt',
		component: () => import('@/views/fzwm/yjzjxt/yjzjxt.vue'),
		children: [
			{
				path: 'yjzjxt',
				name: 'yjzjxt',
				component: () => import('@/views/fzwm/yjzjxt/yjzjxtCon.vue')
			},
			{
				path: '/yjzjxt/yjzjxtDetails',
				name: 'yjzjxtDetails',
				component: () => import('@/views/fzwm/yjzjxt/details.vue')
			},
			{
				path: '/yjzjxt/yjzjxtList',
				name: 'yjzjxtList',
				component: () => import('@/views/fzwm/yjzjxt/list.vue')
			}
		]
	},
	{
		path: '',
		component: () => import('@/views/view.vue'),
		children: [
			{//注册
				path: '/register',
				name: 'register',
				component: () => import('@/views/login/register.vue'),
				meta: {
					title: '用户注册'
				},
			},
			{//忘记密码
				path: '/forget',
				name: 'forget',
				component: () => import('@/views/login/forget.vue'),
				meta: {
					title: '忘记密码'
				},
			},
			{//个人中心
				path: '/center',
				name: 'center',
				component: () => import('@/views/center/center.vue'),
				meta: {
					title: '个人中心'
				},
			},
			{//个人中心-我的留言详情
				path: '/wdlyDetails',
				name: 'wdlyDetails',
				component: () => import('@/views/center/wdlyDetails.vue'),
			},
			{//强制修改密码
				path: '/initpassword',
				name: 'initpassword',
				component: () => import('@/views/center/initpassword.vue'),
			}
		]
	},
	{//搜索
		path: '/search',
		name: '/search',
		component: () => import('@/views/search/search.vue'),
	},
	// 登录
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/login.vue')
	},
	// 智能问答PC端
	{
		path: '/questionAnswer',
		name: 'questionAnswer',
		component: () => import('@/views/questionAnswer/index.vue')
	},
	// 智能问答手机端
	{
		path: '/questionAnswerPhone',
		name: 'questionAnswerPhone',
		component: () => import('@/views/questionAnswer/phone.vue')

	},
	// 法治地图手机端
	{
		path: '/phoneMap',
		name: 'phoneMap',
		component: () => import('@/views/index//fzdt/phoneMap.vue')

	},
	{
	  // 微信/钉钉  文章分享二维码页面
	  path: "/Share/toShare",
	  name: "toShare",
	  component: () => import("../views/list-details/toShare.vue"),
	},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
