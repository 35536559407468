import 'core-js/stable';
import 'regenerator-runtime/runtime';
import "babel-polyfill";
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import api from "./apis/answer.js";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

Vue.config.productionTip = false;

// Vue.prototype.$api = api;

Vue.prototype.$BusEvent = new Vue();


// 过滤器
import "@/utils/filter.js";

// 字体
import '@/assets/font/font.css';

// 引入高德地图
import VueAMap from "vue-amap";

Vue.use(VueAMap);
// 
import {
	getTimeForm,
	removeHtmlStyle
} from "@/utils/formatTime";
Vue.prototype.getTimeForm = getTimeForm
Vue.prototype.removeHtmlStyle = removeHtmlStyle
// 初始化vue-amap
VueAMap.initAMapApiLoader({
	// 高德的key
	key: "8bcd5d53aee035144d261311c2aba4a4",
	// 插件集合
	plugin: [
		"AMap.Autocomplete",
		"AMap.PlaceSearch",
		"AMap.Scale",
		"AMap.OverView",
		"AMap.ToolBar",
		"AMap.MapType",
		"AMap.PolyEditor",
		"AMap.CircleEditor",
	],
	// 高德 sdk 版本，默认为 1.4.4
	v: "1.4.4",
});

new Vue({
	router,
	store,
	render: (h) => h(App),
	data: {
		currentTime: ''
	},
	created() {
		this.getCurrentTime();
	},
	methods: {
		getCurrentTime() {
			const date = new Date();
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = date.getDate().toString().padStart(2, '0');
			const hours = date.getHours().toString().padStart(2, '0');
			const minutes = date.getMinutes().toString().padStart(2, '0');
			const seconds = date.getSeconds().toString().padStart(2, '0');
			const currentTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
			this.currentTime = currentTime;
		}
	},
}).$mount("#app");

// 路由跳转时定位到页面顶部
router.afterEach((to, from, next) => {

	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	
	
	
});

// router.afterEach((to, from, next) => {
// 	console.log("用户信息a")
//   document.body.scrollTop = 0;
//   document.documentElement.scrollTop = 0;
// });

// 设置移动端个别页面适配问题
router.beforeEach((to, from, next) => {
if (to.path != '/initpassword' &&
		store.state.userinfo &&
		store.state.userinfo.password_update_info &&
		store.state.userinfo.password_update_info.update_type &&
		undefined != store.state.userinfo.password_update_info.update_type &&
		null != store.state.userinfo.password_update_info.update_type &&
		2 == store.state.userinfo.password_update_info.update_type
	) {
		next({
			path: "/initpassword",
			query: {
				update_type: store.state.userinfo.password_update_info.update_type,
				ex_day: store.state.userinfo.password_update_info.password_update_expire_time
			}
		});
		return;
	}
	const meta = document.createElement('meta');
	meta.name = 'viewport';
	if (to.path == '/xzzfjdpt') {
		meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
		document.getElementsByTagName('head')[0].appendChild(meta);
	} else {
		meta.content = 'width=device-width,user-scalable=yes';
		document.getElementsByTagName('head')[0].appendChild(meta);
	}
	next();
})